import { Notification, Text } from "@mantine/core";
import { useToastStore } from "../../config/store/toastStore";
import { IconCheck } from "@tabler/icons";
import { useEffect } from "react";

export function MyToast() {
  const { hideToast, toast } = useToastStore((state) => state);

  useEffect(() => {
    if (toast.show) {
      setTimeout(() => {
        hideToast();
      }, toast.duration);
    }
  }, [hideToast, toast.show, toast]);

  return (
    <div className="fixed top-4 left-4" style={{ zIndex: 9999999 }}>
      {toast.show && (
        <Notification
          title={
            <Text className="!text-white !font-extrabold" size="lg">
              {toast.title}
            </Text>
          }
          onClose={hideToast}
          icon={<IconCheck size={18} />}
          color="teal"
          styles={{
            root: {
              border: "none",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
          }}
        >
          <Text color="white">{toast.description}</Text>
        </Notification>
      )}
    </div>
  );
}
